import axios from "axios";
import { ElMessage } from "element-plus";


const defaultError = () => ElMessage.error("发生了一些错误，请联系管理员")
const defaultFailure = (message) => ElMessage.warning(message)
axios.defaults.headers.common['token'] = sessionStorage.getItem("token") || '' ;
// error = defaultError
function post(url, data, success, failure = defaultFailure, error = defaultError) {
    // console.log(url)
    axios.post(url, data, {
      
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        },
        withCredentials:true
    }).then((res)=>{
   
        if (res.data.flag) {
            success(res.data)
        } else {
            // console.log(res.data.data+":" + res.data.status)
            failure(res.data.data, res.data.status)
        }
    }).catch(error)
}

function get(url, data, success, failure = defaultFailure,error = defaultError ) {

    axios.get(url, data, {
        withCredentials:true
    }).then((res)=>{
   
        if (res.data.flag) {
          
             success(res.data)
        } else {
            failure(res.data.data, res.data.status)
        }
    }).catch(error)
}

export {get, post}