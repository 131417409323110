<script >
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";

import { onMounted } from "vue";
export default defineComponent({
  setup() {
    onMounted(() => {
      // 关闭浏览器窗口的时候清空浏览器缓存在localStorage的数据
      // window.onbeforeunload = function (e) {
      //   var storage = window.localStorage;
      //   storage.clear();
      // };
    });
    return {};
  },
});
</script>

<template>
  <!-- <Header></Header> -->
  <RouterView />
  <!-- <Footer></Footer> -->
</template>

<style >
.el-overlay.is-message-box .el-overlay-message-box {
  z-index: 99;
}
</style>
