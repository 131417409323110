import { createApp } from 'vue'
import store from './stores'
import 'animate.css';
import "./assets/css/reset.css"
import App from './App.vue'
import router from './router'
import "element-plus/theme-chalk/el-message.css";
import "element-plus/theme-chalk/el-message-box.css"; 
import ios from "../src/utils/ios"



// 响应式
import "./assets/css/min1920.css"
import "./assets/css/min1680.css"
import "./assets/css/min1550.css"
import "./assets/css/min1366.css"
import "./assets/css/min1260.css"
import "./assets/css/min1024.css"
import "./assets/css/min992.css"
import "./assets/css/min750.css"
import "./assets/css/min479.css"


const app = createApp(App)
ios()
app.use(store)
app.use(router)

app.mount('#app')
