import { createRouter, createWebHistory,createWebHashHistory } from 'vue-router'

const router = createRouter({
  // history: createWebHistory(import.meta.env.BASE_URL),
  history: createWebHashHistory(),
  routes: [
    {
      path: '/',
      name:'welcome',
      component: () => import('@/views/WelcomeView.vue')
    },
    {
      path: '/home',
      name:'home',
      component: () => import('@/views/home/HomeView.vue')
    },
    {
      path: '/login',
      name:'login',
      component: () => import('@/views/LoginView.vue')
    },
    {
      path: '/register',
      name:'register',
      component: () => import('@/views/RegisterView.vue')
    },
    {
      path: '/market',
      name:'market',
      component: () => import('@/views/MarketView.vue')
    },
    {
      path: '/help',
      name:'help',
      component: () => import('@/views/HelpView.vue')
    },
    {
      path: '/forget',
      name: 'forget',
      component: () => import('@/views/ForgetView.vue')
    },
    {
      path: '/agreement',
      name: 'agreement',
      component: () => import('@/views/agreement/agreement.vue')
    },
    {
      path: '/loyguan',
      name: 'loyguan',
      component: () => import('@/views/loyguan.vue')
    }
  ]
})

export default router
