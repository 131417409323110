import { defineStore } from 'pinia'
import { reactive } from 'vue'

// export const useUserStore = defineStore({
//   id: 'user',
//   state: () => {
//     return {
//       token: ''
//     }
//   },
//   actions:{
//     //设置token
//   	setToken( token ){
//   		this.token = token;
//   	},
//     //清除token
//     clearToken(){
//       this.token = '';
//     }
//   },
//   // 开启数据缓存
//   persist: {
//     enabled: true,
//     strategies: [{
//       key: 'xiaoluxian_user',
//       storage: localStorage,
//       //paths: ['token']
//     }]
//   }
// })

export const useUserStore = defineStore({
  id: 'user',
  state: () => {
    return {
      user: null
    }
  },
  actions:{
    //设置user
  	setUser( user ){
  		this.user = user;
  	},
    //清除user
    clearUser(){
      this.user = null;
    }
  },
  // 开启数据缓存
  persist: {
    enabled: true,
    strategies: [{
      key: 'meta_platform_user',
      storage: localStorage,
      // paths: ['user']
    }]
  }
})

// export const useUserStore = defineStore('store', () => {
//   const auth = reactive({
//     user: null
//   })
//   return { auth }
// })